







import { Component, Vue } from "vue-property-decorator";
import Navbar from "@/components/home_2022/Navbar.vue";
@Component({
    components: {
        "al-navbar": Navbar
    }
})
export default class Header extends Vue {
    // created(){
    // }
    // mounted(){
    // }
}
