































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import LoginTypeDialog from "@/components/sign/LoginTypeDialog.vue";

@Component({
    components: {
        "el-collapse-transition": CollapseTransition,
        LoginTypeDialog,
    },
})
export default class Navbar extends Vue {
    @Getter("getToken") token: any;
    @Action("getSetToken") setToken: any;
    @Getter("getLang") lang: any;
    @Action("getSetLang") setLangCommit: any;
    @Getter("getUser") getUser: any;
    @Action("clear") clear: any;

    get showLoginBtn() {
        if (this.token) {
            return false;
        } else {
            return true;
        }
    }

    isshow = false; //是否显示下拉列表
    isUsercenterShow = false;

    registDialog: boolean = false; //是否显示注册种类选择弹窗

    get isDropmenuActived() {
        return (item: any) => {
            if (item.children) {
                // console.log(item.children);
                return item.children.some((e: any) => {
                    return e.ref == this.activeIndex;
                });
            } else {
                return false;
            }
        };
    }

    get nav() {
        let opt = [
            {
                ref: "home",
                isLink: "scroll",
                target: "top",
                content: this.$t("scaffold.home.home"),
                content_inverse: this.$t("scaffold.home.home_inverse"),
            },
            {
                ref: "aboutActivity",
                isLink: "router",
                target: "aboutActivity",
                content: this.$t("scaffold.home.about"),
                content_inverse: this.$t("scaffold.home.about_inverse"),
            },
            {
                ref: "cont_3",
                isLink: "dropdown",
                target: "cont_3",
                content: `${this.$t("scaffold.home.review")}`,
                content_inverse: this.$t("scaffold.home.review_inverse"),
                icon: "angle-down",
                control: "isshow",
                children: [
                    // {
                    //     ref: "projectlist2020",
                    //     isLink: "link",
                    //     target: "http://besttt.ciccst.org.cn/#/projectList",
                    //     content: `${this.$t("scaffold.home.2020projectList")}`,
                    //     content_inverse: `${this.$t(
                    //         "scaffold.home.2020projectList_inverse"
                    //     )}`,
                    // },
                    {
                        ref: "projectlist2020_pro",
                        isLink: "link",
                        target: "https://www.ciste.org.cn/index.php?m=content&c=index&a=lists&catid=98",
                        content: `${this.$t(
                            "scaffold.home.projectlist2020_pro"
                        )}`,
                        content_inverse: `${this.$t(
                            "scaffold.home.projectlist2020_pro_inverse"
                        )}`,
                    },
                    {
                        ref: "projectlist2021",
                        isLink: "router",
                        target: "case-list",
                        content: `2021年百佳案例榜单`,
                        content_inverse: ` 2021 Practice List `,
                    },
                    // {
                    //     ref: "expertlist2020",
                    //     isLink: "router",
                    //     target: "expertlist2020",
                    //     content: `${this.$t("scaffold.home.expertlist2020")}`,
                    //     content_inverse: `${this.$t(
                    //         "scaffold.home.expertlist2020_inverse"
                    //     )}`,
                    // },
                ],
            },
            // {
            //     isLink: "router",
            //     ref: "expertlist2021",
            //     target: "expertlist2021",
            //     content: this.$t("scaffold.home.expert"),
            //     content_inverse: this.$t("scaffold.home.expert_inverse"),
            // },
            // {
            //     isLink: "router",
            //     ref: "caseList",
            //     target: "case-list",
            //     content: "百佳案例榜单",
            //     content_inverse: "List"
            // }
            // {
            //     isLink: "btn",
            //     ref: "registBtn",
            //     target: () => {
            //         this.registDialog = true;
            //     },
            //     params: { step: 1 },
            //     content: this.$t("scaffold.home.signNow")
            // }

            // {
            //     isLink: "router",
            //     ref: "registBtn",
            //     target: "register",
            //     params: { step: 1 },
            //     content: this.$t("scaffold.home.signNow")
            // }
        ];
        return opt;
    }

    get nav_login() {
        // console.log(this.getUser("expert_id"));

        let opt: any = [
            // {
            //     isLink: "btn",
            //     ref: "langBtn",
            //     target: this.changeLang,
            //     content: this.langtxt(),
            //     icon: "language"
            // },
            // {
            //     isLink: "router",
            //     ref: "registBtn",
            //     target: "register",
            //     params: {
            //         step: 1,
            //         pid: this.getUser("case_id")
            //             ? this.getUser("case_id")
            //             : undefined,
            //     },
            //     content: this.$t("scaffold.home.signNow"),
            // },
            // {
            //     isLink: "btn",
            //     ref: "registBtn",
            //     target: ()=>{
            //         alert("暂未开放")
            //     },
            //     params: { step: 1 },
            //     content: this.$t("scaffold.home.signNow")
            // },
            // {
            //     isLink: "|",
            //     ref: "|",
            //     target: "|",
            //     content: "|",
            // },
            {
                isLink: "router",
                ref: "loginBtn",
                target: "login-expert",
                content: this.$t("scaffold.login"),
                icon: "sign-in-alt",
            },
            // {
            //     isLink: "btn",
            //     ref: "loginBtn",
            //     target: () => {
            //         this.registDialog = true;
            //     },
            //     content: this.$t("scaffold.login"),
            //     icon: "sign-in-alt",
            // },
            // {
            //     isLink: "btn",
            //     ref: "loginBtn",
            //     target: ()=>{
            //         alert("暂未开放")
            //     },
            //     content: this.$t("scaffold.login"),
            //     icon: "sign-in-alt"
            // }
        ];

        if (this.showLoginBtn) {
            if (opt.findIndex((item: any) => item.ref == "loginBtn") == -1) {
                opt.push({
                    isLink: "router",
                    ref: "loginBtn",
                    target: "login-expert",
                    content: this.$t("scaffold.login"),
                    icon: "sign-in-alt",
                });
            } else {
            }
        } else {
            if (opt.findIndex((item: any) => item.ref == "loginBtn") != -1) {
                opt.splice(
                    opt.findIndex((item: any) => item.ref == "loginBtn"),
                    1
                );
                if (this.getUser("expert_id")) {
                    opt.push({
                        isLink: "router",
                        ref: "goAssess",
                        target: "assessList",
                        params: {
                            eid: this.getUser("expert_id"),
                        },
                        content: "案例评审",
                    });
                    opt.push({
                        isLink: "btn",
                        ref: "logout",
                        target: this.logout,
                        content: this.$t("scaffold.logout"),
                        icon: "sign-out-alt",
                    });
                } else
                    opt.push({
                        ref: "cont_3",
                        isLink: "dropdown",
                        target: "login",
                        content: `${this.$t("scaffold.usercenter")}`,
                        icon: "angle-down",
                        control: "isUsercenterShow",
                        children: [
                            {
                                ref: "userinfo",
                                isLink: "router",
                                target: "userinfo",
                                content: `${this.$t("scaffold.userinfo")}`,
                            },
                            // {
                            //     ref: "register",
                            //     isLink: "router",
                            //     target: "register",
                            //     content: `${this.$t("scaffold.projectRegist")}`,
                            //     params: { step: "1" }
                            // },
                            {
                                ref: "projectBasic",
                                isLink: "router",
                                target: "projectBasic",
                                content: `${this.$t("scaffold.projectManage")}`,
                            },

                            {
                                ref: "logout",
                                isLink: "btn",
                                target: this.logout,
                                content: `${this.$t("scaffold.logout")}`,
                            },
                        ],
                    });
            } else {
                if (this.getUser("expert_id"))
                    opt.push({
                        isLink: "btn",
                        ref: "logout",
                        target: this.logout,
                        content: this.$t("scaffold.logout"),
                        icon: "sign-out-alt",
                    });
                else
                    opt.push({
                        ref: "cont_3",
                        isLink: "dropdown",
                        target: "login",
                        content: `${this.$t("scaffold.usercenter")}`,
                        icon: "angle-down",
                        control: "isUsercenterShow",
                        children: [
                            {
                                ref: "userinfo",
                                isLink: "router",
                                target: "userinfo",
                                content: `${this.$t("scaffold.userinfo")}`,
                            },
                            {
                                ref: "register",
                                isLink: "router",
                                target: "register",
                                content: `${this.$t("scaffold.projectRegist")}`,
                                params: { step: "1" },
                            },
                            {
                                ref: "logout",
                                isLink: "btn",
                                target: this.logout,
                                content: `${this.$t("scaffold.logout")}`,
                            },
                        ],
                    });
            }
        }

        return opt;
    }

    get nav_mobile() {
        let opt = [...this.nav, ...this.nav_login];
        if (opt.findIndex((item) => item.isLink === "|") !== -1) {
            opt.splice(
                opt.findIndex((item) => item.isLink === "|"),
                1
            );
        }
        return opt;
    }

    activeIndex: any = "0";
    isMobileNavShow = false;

    /* 返回切换语言按钮的文本 */
    langtxt() {
        if (this.lang == "zh") {
            return "Eng";
        } else if (this.lang == "en") {
            return "中";
        } else {
            return "中";
        }
    }

    /* 切换语言 */
    changeLang() {
        if (this.lang == "zh") {
            sessionStorage.setItem("lang", "en");
            this.setLangCommit("en");
        } else if (this.lang == "en") {
            sessionStorage.setItem("lang", "zh");
            this.setLangCommit("zh");
        } else {
            sessionStorage.setItem("lang", "zh");
            this.setLangCommit("zh");
        }
    }

    /*
     *  导航栏点击事件
     *  根据isLink参数判断是站内路由还是外部链接或是页面内锚点定位
     *  'link'为站外链接
     *  'router'为站内路由
     *  'scroll'为锚点定位
     */
    handleNavItem(e: any, item: any, key: any) {
        if (item.isLink == "link") {
            window.open(item.target);
        } else if (item.isLink == "router") {
            item.params
                ? this.$router.push({ name: item.target, params: item.params })
                : this.$router.push({ name: item.target });
            // this.$router.push({ name: item.target });
            this.activeIndex = item.ref;
            this.isshow = false;
            this.isUsercenterShow = false;
        } else if (item.isLink == "scroll") {
            if (this.$route.name == "home") {
                if (item.target == "top") {
                    document.documentElement.scrollTo(0, 0);
                    this.activeIndex = "home";
                } else if (item.target == "bottom") {
                    this.handleScroll("footer");
                    this.activeIndex = "home";
                } else {
                    this.handleScroll(item.target);

                    this.activeIndex = item.ref;
                }
            } else {
                // console.log(this.$router.push({ name: "home" }))
                this.$router.push({ name: "home" }).then((res: any) => {
                    if (item.target == "top") {
                        document
                            .getElementsByClassName("container")[0]
                            .scrollTo(0, 0);
                    } else if (item.target == "bottom") {
                        this.handleScroll("footer");
                    } else {
                        this.handleScroll(item.target);
                        this.activeIndex = item.ref;
                    }
                });
            }
        } else if (item.isLink == "btn") {
            item.target();
        } else if (item.isLink == "|") {
        } else if (item.isLink == "dropdown") {
            //@ts-ignore
            this[item.control] = this[item.control] ? false : true;
        } else {
            alert("系统出错：导航项没有给予正确的链接类型");
        }
    }

    /* 响应汉堡点击事件，切换汉堡菜单状态 */
    handleToggleNav(e: any) {
        if (this.isMobileNavShow == true) {
            this.isMobileNavShow = false;
        } else if (this.isMobileNavShow == false) {
            this.isMobileNavShow = true;
        }
    }

    /* 把导航栏数据注入到汉堡菜单 */
    makeMobileNav() {
        // this.nav_mobile = [...this.nav, ...this.nav_login];
    }

    /* 回到顶部 */
    async getScrollTop(el: any) {
        //获取元素距离页面顶部的距离
        // el.getBoundingClientRect();
        // console.log(el.offsetTop);
        return el.offsetTop - 60;
        // if (el.getBoundingClientRect().top < 0) {
        //     return 0 - Number(el.getBoundingClientRect().top);
        // } else {
        //     return Number(el.getBoundingClientRect().top) - 60;
        // }
    }

    /* 上窜 */
    smoothUp(distance: any, total: any, scroll_content: any, step: any) {
        if (distance > total) {
            let _this = this;
            distance -= step;
            scroll_content.scrollTo(0, distance);
            setTimeout(() => {
                _this.smoothUp(distance, total, scroll_content, step);
            }, 10);
        } else {
            window.scrollTo(0, total);
        }
    }

    /* 下跳 */
    smoothDown(distance: any, total: any, scroll_content: any, step: any) {
        /* 
            distance:滚动条距离顶部的距离；
            total: 目标元素距离页面顶部的距离；
            scroll_content: 滚动条容器；
            step:每一步跳的距离
        */
        if (distance < total) {
            let _this = this;
            distance += step;
            scroll_content.scrollTo(0, distance);
            setTimeout(() => {
                _this.smoothDown(distance, total, scroll_content, step);
            }, 10);
        } else {
            window.scrollTo(0, total);
        }
    }

    /* 响应滚动 */
    handleScroll(target: any) {
        let scrollBox = document.documentElement; //滚动条最外层父容器
        let el = document.getElementById(target); //目标元素
        let distance = scrollBox.scrollTop; //滚动条距离顶部的距离

        let _this = this;
        this.getScrollTop(el).then((res) => {
            //返回值为目标元素距离页面顶部的距离
            let step = res / 50;
            if (res > distance) {
                _this.smoothDown(distance, res, scrollBox, step);
            } else {
                let newTotal = distance - res;
                step = newTotal / 50;
                this.smoothUp(distance, res, scrollBox, step);
            }
        });
    }

    /* 滚动到对应模块设置导航栏actived */
    isScroll() {
        // let scrollTop = document.getElementsByClassName("container_home")[0]
        //     .scrollTop;
        let scrollTop = document.documentElement.scrollTop;
        const cont_length =
            document.getElementsByClassName("scrollerItem").length; //锚点数量
        for (let i = 0; i < cont_length; i++) {
            let cont_offsetTop = (
                document.getElementsByClassName("scrollerItem")[i] as any
            ).offsetTop;

            let cont_id = (
                document.getElementsByClassName("scrollerItem")[i] as any
            ).id;

            let cont_name = (
                document.getElementsByClassName("scrollerItem")[i] as any
            ).getAttribute("el");

            let cont_height = (
                document.getElementsByClassName("scrollerItem")[i] as any
            ).offsetHeight;

            if (
                cont_offsetTop <= scrollTop + 60 &&
                cont_offsetTop + cont_height >= scrollTop
            ) {
                this.activeIndex = cont_name;
            }
        }
    }

    /* 设置监听滚动条 */
    setScrollListener = () => {
        document.addEventListener("scroll", this.isScroll, true);
    };

    //点击下拉菜单以外的地方关闭下拉菜单
    isclick(e: any) {
        // console.log(e.path)
        // console.log(e.path.contains(document.getElementsByClassName('dropmenu')[0]))
        // console.log(e.target)
        // console.log(e.target.contains(document.getElementsByClassName('dropmenu')[0]))
        // console.log(document.getElementsByClassName('dropmenu')[0].contains(e.target))
        // console.log(document.getElementsByClassName('dropmenu')[0])
        if (
            document.getElementsByClassName("dropdownbtn")[0].contains(e.target)
        ) {
        } else {
            this.isshow = false;
        }
    }

    setClickListener() {
        document.addEventListener("click", this.isclick, true);
    }

    logout() {
        //注销
        sessionStorage.clear();
        this.setToken("");
        this.$router.push({ name: "home" });
        this.clear();
    }

    @Watch("$route")
    routeChange(to: any, from: any) {
        this.activeIndex = to.name;
    }

    mounted() {
        this.makeMobileNav();
        this.isScroll();
        this.setScrollListener();
        this.setClickListener();
        this.activeIndex = this.$route.name;
    }

    beforeDestroy() {
        document.removeEventListener("scroll", this.isScroll, true);
        document.removeEventListener("click", this.isclick, true);
    }
}
