









import { Component, Vue, Watch } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Component({
    components: {
        "al-header": Header,
        "al-footer": Footer
    }
})
export default class Container extends Vue {
    containerMinHeight = `0px`; //container min-height
    setContainerMinHeight() {
        /* 设置container的style min-height */
        // console.log(document.body.clientHeight);
        this.containerMinHeight = `${document.body.clientHeight}px`;
    }

    @Watch("$route")
    onChangeRorte(nval: any, oval: any) {}

    mounted() {
        this.setContainerMinHeight();
    }
}
