









import { Vue, Component, Watch } from "vue-property-decorator";
import Header from "@/components/home_2022/Header.vue";
import Footer from "@/components/home_2022/Footer.vue";
@Component({
    components: {
        "al-header": Header,
        "al-footer": Footer
    }
})
export default class Container_Home extends Vue {
    containerMinHeight = `0px`; //container min-height
    setContainerMinHeight() {
        /* 设置container的style min-height */
        // console.log(document.body.clientHeight);
        this.containerMinHeight = `${document.body.clientHeight}px`;
    }
    mounted() {
        this.setContainerMinHeight();
    }

    @Watch("$route")
    routerChange() {
        document.documentElement.scrollTop = 0; //路由改变后，页面滚动到顶
    }
}
